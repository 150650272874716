@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@700&display=swap');

.App {
  text-align: center;
  font-family: 'Montserrat', sans-serif;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #2c395d;
  min-height: 80vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: #b87333;
}

h2 {
  text-transform: uppercase;
  margin: 10px 0 20px;
  letter-spacing: 2px;
  border-bottom: 1px #b87333 solid;
  padding: 0 0 20px 0;
  text-shadow: 2px 2px #00000047;
}

.App-header ul {
  list-style: none;
  padding: 0;
  margin: 0 0 50px 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  text-shadow: 2px 2px #00000047;
  letter-spacing: 1px;
}

.App-header ul li {
  display: flex;
  align-items: center;
  text-transform: uppercase;
  width: 50%;
  margin-bottom: 10px;
}

.App-header ul li:nth-child(odd) { 
  justify-content: flex-end;
  width: calc(50% - 10px);
  margin-right: 10px;
}

.App-header ul li span {
  font-size: 14px;
  margin-right: 10px;
}

.contact-link {
  color: #61a6fb;
  font-size: 1rem;
  text-transform: uppercase;
  letter-spacing: 1px;
  text-decoration: none;
  background: rgba(0, 0, 0, .3);
  padding: 13px 15px;
  border-radius: 13px;
  box-shadow: -0px -1px rgba(0, 0, 0, .5), 0px 1px rgba(255, 255, 255, .1);
}

.contact-link:active,
.contact-link:hover {
  text-decoration: underline;
}